// extracted by mini-css-extract-plugin
export var project = "project-module--project--58mzS";
export var projectStatus = "project-module--project-status--dpG2C";
export var production = "project-module--production--38ftW";
export var development = "project-module--development--Curf6";
export var drawerContent = "project-module--drawer-content--Q0ItF";
export var projectContent = "project-module--project-content--msVAW";
export var projectLink = "project-module--project-link--vzG1L";
export var caseStudyLink = "project-module--case-study-link--PsZSn";
export var bounce = "project-module--bounce--KEKyf";
export var bounceHard = "project-module--bounce-hard--VbTp7";